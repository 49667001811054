export const TESTIMONIALS = [
  {
    text:
      'Me encontrei muito depois que passei a usar os florais e o atendimento com a Luciana, faz cuidarmos mais do nosso interior e buscar as verdadeiras prioridades em nossa vida. ',
    autor: 'Vanessa Trasel',
    company: 'Cliente Useflorais',
    thumb: 'Clematis'
  },
  {
    text: 'Gostei do atendimento da Luciane. Me passou segurança nas informações dadas',
    autor: 'Ana Pereira',
    company: 'Cliente Useflorais',
    thumb: 'Mustard'
  },
  {
    text:
      'As aulas com a Lu, são maravilhosas, muito didáticas, tem muito conhecimento, as aulas são leves e descontraídas.',
    autor: 'Fernanda Ramminger',
    company: 'Aluno Useflorais',
    thumb: 'Walnut'
  },
  {
    text:
      'A Lu é uma excelente profissional, muito competente, empática, de um carisma incrível. Super profissional, séria e dedicada com o que faz.',
    autor: 'Simoní Santos',
    company: 'Cliente Useflorais',
    thumb: 'RedChestnut'
  }
]
