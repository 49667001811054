import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import PoppinsLight from '../../fonts/useflorais/Philosopher.woff2'
import PoppinsMedium from '../../fonts/useflorais/Philosopher-Bold.woff2'
import PoppinsRegular from '../../fonts/useflorais/Philosopher.woff2'
import PoppinsSemiBold from '../../fonts/useflorais/Philosopher-Bold.woff2'
import GothicA1Regular from '../../fonts/useflorais/Metropolis-Regular.woff2'
import GothicA1Bold from '../../fonts/useflorais/Metropolis-Bold.woff2'
import GothicA1Medium from '../../fonts/useflorais/Metropolis-Medium.woff2'
import GothicA1SemiBold from '../../fonts/useflorais/Metropolis-SemiBold.woff2'
import fabrands from '../../fonts/useflorais/fa-brands.woff2'
import faregular from '../../fonts/useflorais/fa-regular.woff2'
import falight from '../../fonts/useflorais/fa-light.woff2'
import fasolid from '../../fonts/useflorais/fa-solid.woff2'

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  // TODO filter actually used fields
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(meta)}
    >
      <link rel='preload' as='font' href={PoppinsLight} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={PoppinsMedium} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={PoppinsRegular} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={PoppinsSemiBold} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={GothicA1Regular} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={GothicA1Bold} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={GothicA1Medium} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={GothicA1SemiBold} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={fabrands} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={faregular} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={falight} type='font/woff2' crossorigin='anonymous' />
      <link rel='preload' as='font' href={fasolid} type='font/woff2' crossorigin='anonymous' />
    </Helmet>
  )
}

SEO.defaultProps = {
  author: '',
  lang: 'pt-br',
  meta: [],
  description: '',
  ogImage: '',
  title: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export { SEO }
