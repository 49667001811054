import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Image } from '../UI'
import { WhatsApp } from '../Icons'
import { useCopyToClipboard } from 'react-use'
import {
  HOME_ROUTE,
  ORACULO_FLORES,
  CIRANDA_DE_MULHERES,
  CONSTELACAO_FAMILIAR,
  FLORAIS_ANIMAIS,
  INTRO_SOULCOLLAGE,
  LEITURA_CAMPO_ENERGETICO,
  FLORAIS_OLEOS_ESSENCIAIS,
  JORNADA,
  ATENDIMENTOS_TERAPEUTICOS,
  HOWTOUSE,
  SYSTEMICTERAPY,
  TERAPIA_A_Z,
  FLORAIS_BACH_CRIANCAS,
  CONSTELACAO_FAMILIAR_SISTEMICA,
  POLICY_PRIVACY
} from '../../constants/routes'
import cn from 'classnames'
import styles from './Footer.module.css'

const Footer = () => {
  const { pagSeguro, logoUseflorais } = useStaticQuery(
    graphql`
      query Footer {
        pagSeguro: file(relativePath: { eq: "seloPagSeguro.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoUseflorais: file(relativePath: { eq: "common/logo-footer-useflorais.svg" }) {
          publicURL
        }
      }
    `
  )

  const [showCopied, setShowCopy] = useState(false)
  const [copied, copyToClipboard] = useCopyToClipboard({
    noUserInteraction: false
  })
  const codeBlockRef = useRef(null)
  const defaultCodeSnippet = 'contato@useflorais.com.br'
  const handleCopy = () => {
    copyToClipboard(codeBlockRef.current.innerText)
  }
  const tooltip = cn(styles.tooltip, {
    [styles.tooltipOpen]: showCopied
  })

  useEffect(() => {
    if (!copied.value) {
      return
    }
    setShowCopy(true)
    setTimeout(() => {
      setShowCopy(false)
    }, 2000)
  }, [copied])

  return (
    <footer className={styles.footer}>
      <address>
        <a className={styles.phone} href='tel:51993145751'>
          (51) 99314.5751
        </a>
        <div className={styles.copy}>
          <span ref={codeBlockRef} defaultValue={defaultCodeSnippet}>
            {defaultCodeSnippet}
          </span>
          <button className={styles.copyButton} onClick={handleCopy} />
          <div className={tooltip}>E-mail copiado (:</div>
        </div>
      </address>
      <div className={styles.footerTabs}>
        <dl>
          <dt>Minha Jornada</dt>
          <dd>
            <Link to={JORNADA} title='Sobre a Luciana Moura'>
              Sobre a Luciana Moura
            </Link>
          </dd>
        </dl>
        <dl>
          <dt>Cursos e Formações</dt>
          <dd>
            <Link to={SYSTEMICTERAPY} title='Terapia Floral Sistêmica'>
              Terapia Floral Sistêmica
            </Link>
          </dd>
          <dd>
            <Link to={HOWTOUSE} title='Aprenda a usar Florais de Bach'>
              Aprenda a usar Florais de Bach
            </Link>
          </dd>
          <dd>
            <Link to={CONSTELACAO_FAMILIAR_SISTEMICA} title='Constelação Familiar Sistêmica'>
              Constelação Familiar Sistêmica
            </Link>
          </dd>
          <dt>Cursos Complementares</dt>
          <dd>
            <Link to={FLORAIS_OLEOS_ESSENCIAIS} title='Florais de Bach e Óleos Essenciais'>
              Florais de Bach e Óleos Essenciais
            </Link>
          </dd>
          <dd>
            <Link to={FLORAIS_ANIMAIS} title='Florais de Bach para Animais'>
              Florais de Bach para Animais
            </Link>
          </dd>
          <dd>
            <Link to={INTRO_SOULCOLLAGE} title='Introdutório de SoulCollage ®'>
              Introdutório de SoulCollage ®
            </Link>
          </dd>
          <dd>
            <Link to={LEITURA_CAMPO_ENERGETICO} title='Leitura do Campo Emocional'>
              Leitura do Campo Emocional
            </Link>
          </dd>
          <dd>
            <Link to={ORACULO_FLORES} title='Oráculo das Flores'>
              Oráculo das Flores
            </Link>
          </dd>
          <dd>
            <Link to={TERAPIA_A_Z} title='Terapia Floral de A a Z'>
              Terapia Floral de A a Z
            </Link>
          </dd>
          <dd>
            <Link to={FLORAIS_BACH_CRIANCAS} title='Florais de Bach para Crianças'>
              Florais de Bach para Crianças
            </Link>
          </dd>
        </dl>
        <dl>
          <dt>Atendimento terapêutico</dt>
          <dt>Desenvolvimento Individual</dt>
          <dd>
            <Link to={ATENDIMENTOS_TERAPEUTICOS} title='Como funciona'>
              Como funciona
            </Link>
          </dd>
          <dt>Desenvolvimento em Grupo</dt>
          <dd>
            <Link to={CONSTELACAO_FAMILIAR} title='Workshop de Constelação Familiar'>
              Workshop de Constelação Familiar
            </Link>
          </dd>
          <dd>
            <Link to={CIRANDA_DE_MULHERES} title='Ciranda de Mulheres'>
              Ciranda de Mulheres
            </Link>
          </dd>
        </dl>
      </div>
      <div className={styles.payments}>
        <p>
          Todos os cursos, workshops e atendimentos com <strong>parcelamento facilitado.</strong>
        </p>
        <Image className={styles.paymentPagseguro} fluid={pagSeguro.childImageSharp.fluid} />
        <WhatsApp className={styles.whatsAppIcon} />
      </div>
      <div className={styles.footerAddress}>
        <Link to={HOME_ROUTE} title='Lu Moura'>
          <img alt='Logo UseFlorais - Rodapé' src={logoUseflorais.publicURL} className={styles.siteOwner} />
        </Link>
        <div className={styles.copyright}>
          © Copyright Lu Moura - Todos os direitos reservados. <br />
          <Link to={POLICY_PRIVACY}>Termos de Uso, Privacidade e Segurança</Link>
        </div>
        <div className={styles.socialFooter}>
          <a
            href='https://www.facebook.com/UseFloraisTerapiasAlternativas/'
            target='_blank'
            rel='noopener noreferrer'
            title='Facebook'
          >
            
          </a>
          <a
            href='https://www.instagram.com/luciana_smoura/'
            title='Instagram'
            target='_blank'
            rel='noopener noreferrer'
          >
            
          </a>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
