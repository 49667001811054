import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import SlickCarousel from 'react-slick'
import { useInView } from 'react-intersection-observer'
import { TESTIMONIALS } from '../../constants/testimonials'
import childrenToString from '../../helpers/childrenToString'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '../UI'
import classnames from 'classnames'

import styles from './Carousel.module.css'

function Carousel({ isPurple, isWhite }) {
  const [refDiv, inView] = useInView()
  const [isMounted, setIsMounted] = useState(false)
  const sliderTestimonial = useRef()
  const variationClassName = classnames(styles.carouselWrapper, {
    [styles.carouselWrapperPurple]: isPurple,
    [styles.carouselWrapperWhite]: isWhite
  })

  const clDots = classnames(styles.dot, {
    [styles.dotActive]: isMounted
  })

  const intervalDelay = 6000

  useEffect(() => {
    if (inView) {
      setIsMounted(true)
      sliderTestimonial.current.slickPlay()
      return
    }
  }, [inView, isMounted])

  function shuffle(a) {
    var j, x, i
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
    }
    return a
  }

  const thumbs = useStaticQuery(
    graphql`
      query Testimonials {
        allFile(filter: { relativeDirectory: { glob: "testimonials/*" } }) {
          edges {
            node {
              name
              childImageSharp {
                original {
                  width
                  height
                }
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  const testimonialsRn = shuffle(TESTIMONIALS)

  function renderItems() {
    return testimonialsRn.map((item, index) => {
      const thumb = thumbs.allFile.edges.find(t => t.node.name === item.thumb).node
      const alt = childrenToString(item.autor)

      return (
        <div key={index} className={styles.item}>
          <small className={styles.type}>Depoimento</small>
          <blockquote className={styles.text}>“{item.text}”</blockquote>
          <div className={styles.autor}>
            <figure>
              <Image alt={alt} className={styles.image} fluid={thumb.childImageSharp.fluid} />
            </figure>
            <div className={styles.autorData}>
              <cite className={styles.name}>{item.autor}</cite>
              <cite className={styles.company}>{item.company}</cite>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <div className={variationClassName} ref={refDiv}>
      <SlickCarousel
        ref={slider => (sliderTestimonial.current = slider)}
        dots
        adaptiveHeight
        arrows
        center={false}
        autoplay={isMounted}
        speed={300}
        autoplaySpeed={intervalDelay}
        pauseOnDotsHover={true}
        pauseOnHover={true}
        pauseOnFocus={true}
        slidesPerRow={1}
        slidesToShow={1}
        slidesToScroll={1}
        className={styles.carouselContainer}
        dotsClass={clDots}
        customPaging={i => {
          return (
            <div className={styles.slickDot}>
              <div className={styles.progress}></div>
            </div>
          )
        }}
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesPerRow: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false
            }
          }
        ]}
      >
        {renderItems()}
      </SlickCarousel>
    </div>
  )
}

Carousel.propTypes = {
  className: PropTypes.string,
  isPurple: PropTypes.bool,
  isWhite: PropTypes.bool
}

export { Carousel }
