import React, { useRef } from 'react'
import { motion } from 'framer-motion'
import FocusTrap from 'focus-trap-react'
// import ScrollLock from 'react-scrolllock'
import { useClickAway } from 'react-use'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './Menu.module.css'
import { MenuToggle } from './MenuToggle'
import { MenuItems } from './MenuItems'
import cn from 'classnames'
import { useHeader } from '@hooks'

const Menu = ({ isOpen, toggleOpen }) => {
  const ref = useRef(null)
  const { isMobile } = useHeader()

  const { seloUse } = useStaticQuery(
    graphql`
      query menuQuery {
        seloUse: file(relativePath: { eq: "common/logo-menu.svg" }) {
          publicURL
        }
      }
    `
  )

  const menuClassName = cn(styles.menuWrapper, {
    [styles.menuWrapperOpen]: isOpen && isMobile
  })

  useClickAway(ref, () => {
    if (!isOpen) {
      return
    }

    toggleOpen(false)
  })

  return (
    <FocusTrap active={isOpen}>
      <div className={styles.menuMobile}>
        <div ref={ref} className={menuClassName}>
          <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'} className={styles.menu}>
            <motion.div className={styles.background}>
              <motion.div className={styles.menuTitleMobile}>
                <img
                  alt='Logo Lu Moura - Mobile'
                  src={seloUse.publicURL}
                  className={styles.menuLogo}
                  style={{ position: 'absolute' }}
                />
                <div className={styles.menuTitleText}>
                  Menu <strong>Lu Moura</strong>
                </div>
              </motion.div>
              <MenuItems isOpen={isOpen} toggle={() => toggleOpen(!isOpen)} />
            </motion.div>
          </motion.nav>
        </div>
        <MenuToggle className={styles.toggleHeader} isOpen={isOpen} toggle={() => toggleOpen(!isOpen)} />
      </div>
    </FocusTrap>
  )
}

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired
}

export { Menu }
