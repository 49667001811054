// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-aprenda-a-usar-florais-de-bach-js": () => import("./../src/pages/aprenda-a-usar-florais-de-bach.js" /* webpackChunkName: "component---src-pages-aprenda-a-usar-florais-de-bach-js" */),
  "component---src-pages-ciranda-de-mulheres-js": () => import("./../src/pages/ciranda-de-mulheres.js" /* webpackChunkName: "component---src-pages-ciranda-de-mulheres-js" */),
  "component---src-pages-constelacao-familiar-sistemica-js": () => import("./../src/pages/constelacao-familiar-sistemica.js" /* webpackChunkName: "component---src-pages-constelacao-familiar-sistemica-js" */),
  "component---src-pages-constelacao-familiar-js": () => import("./../src/pages/constelacao-familiar.js" /* webpackChunkName: "component---src-pages-constelacao-familiar-js" */),
  "component---src-pages-desenvolvimento-individual-ou-em-grupo-js": () => import("./../src/pages/desenvolvimento-individual-ou-em-grupo.js" /* webpackChunkName: "component---src-pages-desenvolvimento-individual-ou-em-grupo-js" */),
  "component---src-pages-florais-de-bach-e-oleos-essenciais-js": () => import("./../src/pages/florais-de-bach-e-oleos-essenciais.js" /* webpackChunkName: "component---src-pages-florais-de-bach-e-oleos-essenciais-js" */),
  "component---src-pages-florais-de-bach-para-animais-js": () => import("./../src/pages/florais-de-bach-para-animais.js" /* webpackChunkName: "component---src-pages-florais-de-bach-para-animais-js" */),
  "component---src-pages-florais-de-bach-para-criancas-js": () => import("./../src/pages/florais-de-bach-para-criancas.js" /* webpackChunkName: "component---src-pages-florais-de-bach-para-criancas-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introdutorio-de-soulcollage-js": () => import("./../src/pages/introdutorio-de-soulcollage.js" /* webpackChunkName: "component---src-pages-introdutorio-de-soulcollage-js" */),
  "component---src-pages-leitura-do-campo-energetico-emocional-js": () => import("./../src/pages/leitura-do-campo-energetico-emocional.js" /* webpackChunkName: "component---src-pages-leitura-do-campo-energetico-emocional-js" */),
  "component---src-pages-minha-jornada-js": () => import("./../src/pages/minha-jornada.js" /* webpackChunkName: "component---src-pages-minha-jornada-js" */),
  "component---src-pages-oraculo-das-flores-js": () => import("./../src/pages/oraculo-das-flores.js" /* webpackChunkName: "component---src-pages-oraculo-das-flores-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-terapia-floral-de-a-a-z-js": () => import("./../src/pages/terapia-floral-de-A-a-Z.js" /* webpackChunkName: "component---src-pages-terapia-floral-de-a-a-z-js" */),
  "component---src-pages-terapia-floral-sistemica-js": () => import("./../src/pages/terapia-floral-sistemica.js" /* webpackChunkName: "component---src-pages-terapia-floral-sistemica-js" */)
}

