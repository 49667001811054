import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useCopyToClipboard } from 'react-use'
import { Link } from 'gatsby'
import {
  HOME_ROUTE,
  ORACULO_FLORES,
  CIRANDA_DE_MULHERES,
  CONSTELACAO_FAMILIAR,
  FLORAIS_ANIMAIS,
  INTRO_SOULCOLLAGE,
  LEITURA_CAMPO_ENERGETICO,
  FLORAIS_OLEOS_ESSENCIAIS,
  JORNADA,
  ATENDIMENTOS_TERAPEUTICOS,
  HOWTOUSE,
  SYSTEMICTERAPY,
  WHATSAPPLINK,
  CONSTELACAO_FAMILIAR_SISTEMICA,
  FLORAIS_BACH_CRIANCAS,
  TERAPIA_A_Z
} from '../../constants/routes'

import styles from './MenuItems.module.css'

const Item = props => {
  return <motion.li className={styles.item}>{props.children}</motion.li>
}

const SubItem = props => {
  const toggle = props.toggle || null

  return (
    <motion.dd className={styles.subItem} onClick={toggle}>
      {props.children}
    </motion.dd>
  )
}

Item.propTypes = {
  children: PropTypes.array.isRequired
}

SubItem.propTypes = {
  children: PropTypes.object.isRequired,
  toggle: PropTypes.func
}

const MenuItems = ({ toggle, isOpen }) => {
  const [isSubOne, subOneOpen] = useState(false)
  const [isSubThree, subThreeOpen] = useState(false)

  const [showCopied, setShowCopy] = useState(false)
  const [copied, copyToClipboard] = useCopyToClipboard({
    noUserInteraction: false
  })
  const codeBlockRef = useRef(null)
  const defaultCodeSnippet = 'contato@useflorais.com.br'
  const handleCopy = () => {
    copyToClipboard(codeBlockRef.current.innerText)
  }
  const tooltip = cn(styles.tooltip, {
    [styles.tooltipOpen]: showCopied
  })

  useEffect(() => {
    if (!isOpen) {
      subOneOpen(false)
      subThreeOpen(false)
      return
    }

    if (!copied.value) {
      return
    }
    setShowCopy(true)
    setTimeout(() => {
      setShowCopy(false)
    }, 2000)
  }, [isOpen, copied])

  const cnWithSubmenuOne = cn(styles.withSubmenu, {
    [styles.subOneOpen]: isSubOne
  })

  const cnWithWrapperOne = cn(styles.subItemsWrapper, {
    [styles.wrapperOneOpen]: isSubOne
  })

  const cnWithSubmenuThree = cn(styles.withSubmenu, {
    [styles.subThreeOpen]: isSubThree
  })

  const cnWithWrapperThree = cn(styles.subItemsWrapper, {
    [styles.wrapperThreeOpen]: isSubThree
  })

  return (
    <>
      <motion.ul className={styles.items}>
        <Item toggle={toggle} subOneOpen={subOneOpen}>
          <Link to={HOME_ROUTE} className={styles.mobileItem}>
            Principal
          </Link>
          <Link
            className={cnWithSubmenuOne}
            to=''
            onClick={e => {
              e.preventDefault()
              subOneOpen(!isSubOne)
            }}
          >
            Cursos e formações
          </Link>
          <motion.div className={cnWithWrapperOne}>
            <motion.dl className={styles.subItems}>
              <dt className={styles.subItemTitle}>Formação</dt>
              <SubItem toggle={toggle}>
                <Link to={SYSTEMICTERAPY} title='Terapia Floral Sistêmica'>
                  Terapia Floral Sistêmica
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={HOWTOUSE} title='Aprenda a usar Florais de Bach'>
                  Aprenda a usar Florais de Bach
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={CONSTELACAO_FAMILIAR_SISTEMICA} title='Constelação Familiar Sistêmica'>
                  Constelação Familiar Sistêmica
                </Link>
              </SubItem>
              <dt className={styles.subItemTitle}>Cursos Complementares</dt>
              <SubItem toggle={toggle}>
                <Link to={FLORAIS_OLEOS_ESSENCIAIS} title='Florais de Bach e Óleos Essenciais'>
                  Florais de Bach e Óleos Essenciais
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={FLORAIS_ANIMAIS} title='Florais de Bach para Animais'>
                  Florais de Bach para Animais
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={INTRO_SOULCOLLAGE} title='Introdutório de SoulCollage ®'>
                  Introdutório de SoulCollage ®
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={ORACULO_FLORES} title='Oráculo das Flores'>
                  Oráculo das Flores
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={LEITURA_CAMPO_ENERGETICO} title='Leitura do Campo Emocional'>
                  Leitura do Campo Emocional
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={TERAPIA_A_Z} title='Terapia Floral de A a Z'>
                  Terapia Floral de A a Z
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={FLORAIS_BACH_CRIANCAS} title='Florais de Bach para Crianças'>
                  Florais de Bach para Crianças
                </Link>
              </SubItem>
            </motion.dl>
            <motion.dl className={`${styles.subItems} ${styles.subItemsNewWindow}`}>
              <dd className={styles.newWindow}>
                <a href={WHATSAPPLINK} title='Saiba Mais' target='_blank' rel='noopener noreferrer'>
                  <span>Saiba Mais</span>
                  <i></i>
                </a>
              </dd>
            </motion.dl>
          </motion.div>
        </Item>
        <Item toggle={toggle} subThreeOpen={subThreeOpen}>
          <Link
            to=''
            className={cnWithSubmenuThree}
            onClick={e => {
              e.preventDefault()
              subThreeOpen(!isSubThree)
            }}
          >
            Atendimento terapêutico
          </Link>
          <motion.div className={cnWithWrapperThree}>
            <motion.dl className={styles.subItems}>
              <dt className={styles.subItemTitle}>Desenvolvimento Individual</dt>
              <SubItem toggle={toggle}>
                <Link to={ATENDIMENTOS_TERAPEUTICOS} title='Como funciona'>
                  Como funciona
                </Link>
              </SubItem>
              <dt className={styles.subItemTitle}>Desenvolvimento em Grupo</dt>
              <SubItem toggle={toggle}>
                <Link to={CONSTELACAO_FAMILIAR} title='Workshop de Constelação Familiar'>
                  Workshop de Constelação Familiar
                </Link>
              </SubItem>
              <SubItem toggle={toggle}>
                <Link to={CIRANDA_DE_MULHERES} title='Ciranda de Mulheres'>
                  Ciranda de Mulheres
                </Link>
              </SubItem>
            </motion.dl>
            <motion.dl className={`${styles.subItems} ${styles.subItemsNewWindow}`}>
              <dd className={styles.newWindow}>
                <a href={WHATSAPPLINK} title='Agendar Atendimento' target='_blank' rel='noopener noreferrer'>
                  <span>Agendar Atendimento</span>
                  <i></i>
                </a>
              </dd>
            </motion.dl>
          </motion.div>
        </Item>
        <div className={styles.item}>
          <Link to={JORNADA} title='Minha Jornada'>
            Minha Jornada
          </Link>
        </div>
      </motion.ul>
      <div className={styles.address}>
        <a href='tel:51993145751'>
          (51) 99314.5751 <span className={styles.call}>(ligar)</span>
        </a>

        <br />
        <div className={styles.copy}>
          <span ref={codeBlockRef} defaultValue={defaultCodeSnippet}>
            {defaultCodeSnippet}
          </span>
          <button className={styles.copyButton} onClick={handleCopy} />
          <div className={tooltip}>E-mail copiado (:</div>
        </div>
      </div>
      <div className={styles.socialSide}>
        <a
          href='https://www.facebook.com/UseFloraisTerapiasAlternativas/'
          target='_blank'
          rel='noopener noreferrer'
          title='Facebook'
        >
          
        </a>
        <a href='https://www.instagram.com/luciana_smoura/' title='Instagram' target='_blank' rel='noopener noreferrer'>
          
        </a>
      </div>
    </>
  )
}

MenuItems.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export { MenuItems }
