import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { WHATSAPPLINK } from '../../constants/routes'
import st from './WhatsApp.module.css'

const WhatsApp = ({ className }) => {
  const [ref, inView] = useInView()
  const [isShow, setIsShow] = useState(false)
  const setClassName = cn(className, st.wtWrapper, {
    [st.wtShow]: isShow
  })

  useEffect(() => {
    if (inView) {
      setTimeout(() => setIsShow(true), 5)
      return
    }
  }, [inView, isShow])

  return (
    <div className={setClassName} ref={ref}>
      <a href={WHATSAPPLINK} rel='noopener noreferrer' target='_blank'>
        <div className={st.svgObjectWrapper}>
          <svg className={st.svgObject} viewBox='0 0 62 65' xmlns='http://www.w3.org/2000/svg'>
            <circle className={st.greenCircle} cx='31' cy='34' r='31' />
            <path
              className={st.wLogo}
              d='M40.8 24.1C38.2 21.5 34.7 20 31 20c-7.6 0-13.9 6.3-13.9 13.9 0 2.5.7 4.9 1.9 6.9L17 48l7.3-1.9c2.1 1.1 4.3 1.7 6.6 1.7 7.7 0 14.1-6.3 14.1-13.9 0-3.7-1.6-7.1-4.2-9.8zm-9.9 21.3c-2.1 0-4.1-.6-5.9-1.6l-.4-.3-4.4 1.2 1.2-4.3-.3-.4c-1.1-1.9-1.8-4-1.8-6.2 0-6.3 5.2-11.5 11.6-11.5 3.1 0 5.9 1.2 8.1 3.4s3.5 5.1 3.5 8.2c.1 6.4-5.2 11.5-11.6 11.5zm6.4-8.6c-.4-.2-2.1-1-2.4-1.1s-.6-.2-.8.2c-.2.3-.9 1.1-1.1 1.4-.3.2-.4.3-.8.1-2.1-1-3.4-1.8-4.8-4.1-.4-.6.4-.6 1-1.9.1-.3.1-.4 0-.6s-.8-1.9-1.1-2.6c-.3-.7-.6-.6-.8-.6h-.6c-.3 0-.6.1-.9.4s-1.2 1.2-1.2 2.9c0 1.8 1.2 3.4 1.4 3.6.2.2 2.4 3.7 5.9 5.2 2.2 1 3.1 1.1 4.2.9.6-.1 2-.8 2.3-1.6s.3-1.5.2-1.6c.1-.3-.2-.4-.5-.6z'
            />
            <g>
              <circle className={st.redCircle} cx='51' cy='11' r='11' />
              <path className={st.msgNumber} d='M49.2 7.3V5.8h2.9V16h-1.6V7.3h-1.3z' />
            </g>
          </svg>
          <div className={st.greenCircleShadow} />
        </div>
      </a>
    </div>
  )
}

WhatsApp.propTypes = {
  className: PropTypes.string
}

export { WhatsApp }
