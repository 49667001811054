import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { Header, Footer } from './'
import { HeaderProvider } from '../../context/'
import styles from './Layout.module.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={styles.siteWrapper}>
      <HeaderProvider>
        <Header siteTitle={data.site.siteMetadata.title} />
      </HeaderProvider>
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export { Layout }
