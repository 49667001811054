import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import cn from 'classnames'
import { Image } from '../UI'
import SlickCarousel from 'react-slick'
import styles from './Slideshow.module.css'

const Slideshow = ({ className }) => {
  const [refDiv, inView] = useInView()
  const [isMounted, setIsMounted] = useState(false)
  const sliderTrends = useRef()
  const intervalDelay = 6000
  const slideClassName = cn(styles.slide, className)

  useEffect(() => {
    if (inView) {
      setIsMounted(true)
      sliderTrends.current.slickPlay()
      return
    }
  }, [inView, isMounted])

  const { images } = useStaticQuery(
    graphql`
      query SlidesQuery {
        images: allFile(filter: { relativePath: { glob: "diferenciais/*" } }) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 316) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  function shuffle(a) {
    var j, x, i
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
    }
    return a
  }

  const trendingEvents = shuffle(images.edges)

  function renderImages() {
    return trendingEvents.map((item, index) => {
      const thumb = images.edges[index].node

      return (
        <div key={index} className={styles.item}>
          <figure>
            <Image className={styles.image} fluid={thumb.childImageSharp.fluid} />
          </figure>
        </div>
      )
    })
  }
  return (
    <div className={slideClassName} ref={refDiv}>
      <SlickCarousel
        ref={slider => (sliderTrends.current = slider)}
        dots={false}
        fade
        adaptiveHeight={false}
        arrows={false}
        center={false}
        autoplay={isMounted}
        speed={3000}
        autoplaySpeed={intervalDelay}
        pauseOnDotsHover={false}
        pauseOnHover={false}
        slidesPerRow={1}
        slidesToShow={1}
        slidesToScroll={1}
        className={styles.imageContainer}
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesPerRow: 1
            }
          }
        ]}
      >
        {renderImages()}
      </SlickCarousel>
    </div>
  )
}

Slideshow.propTypes = {
  className: PropTypes.string
}

export { Slideshow }
