import * as React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './MenuToggle.module.css'

const MenuToggle = ({ toggle, isOpen, className }) => {
  const classNameButton = classnames(className, styles.toggle, {
    [styles.toggleOpen]: isOpen
  })

  const classNameIcon = classnames(styles.icon, className, {
    [styles.iconIsActive]: isOpen
  })

  return (
    <button className={classNameButton} onClick={toggle}>
      <div className={classNameIcon}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  )
}

MenuToggle.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export { MenuToggle }
