import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from 'react-use'

import { HeaderContext } from './HeaderContext'

const MOBILE_BREAKPOINT = 1200

function HeaderProvider(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_BREAKPOINT

  const context = {
    isMobile,
    isMenuOpen,
    setIsMenuOpen,
    menuId: 'headerMenu',
    menuToggleId: 'headerMenuToggle'
  }

  return <HeaderContext.Provider value={context}>{props.children}</HeaderContext.Provider>
}

HeaderProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export { HeaderProvider }
