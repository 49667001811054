import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { HOME_ROUTE } from '../../constants/routes'
import cn from 'classnames'

import LogoHeader from '../../images/logo.svg'
import { Menu } from '../Menu/Menu'
import { useHeader } from '@hooks'

import styles from './Header.module.css'

const Header = () => {
  const { isMobile } = useHeader()
  const [isOpen, toggleOpen] = useState(false)
  const headerClassName = cn(styles.header, {
    [styles.headerOpen]: isOpen && isMobile
  })

  return (
    <header className={headerClassName}>
      <div className={styles.container}>
        <Link to={HOME_ROUTE} className={styles.companyLink} title='Página inicial ;)'>
          <LogoHeader className={styles.logo} />
        </Link>
        <Menu toggleOpen={toggleOpen} isOpen={isOpen} />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export { Header }
