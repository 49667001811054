export const HOME_ROUTE = '/'
export const ORACULO_FLORES = '/oraculo-das-flores'
export const CIRANDA_DE_MULHERES = '/ciranda-de-mulheres'
export const CONSTELACAO_FAMILIAR = '/constelacao-familiar'
export const FLORAIS_ANIMAIS = '/florais-de-bach-para-animais'
export const INTRO_SOULCOLLAGE = '/introdutorio-de-soulcollage'
export const LEITURA_CAMPO_ENERGETICO = '/leitura-do-campo-energetico-emocional'
export const FLORAIS_OLEOS_ESSENCIAIS = '/florais-de-bach-e-oleos-essenciais'
export const JORNADA = '/minha-jornada'
export const ATENDIMENTOS_TERAPEUTICOS = '/desenvolvimento-individual-ou-em-grupo'
export const POLICY_PRIVACY = '/politica-de-privacidade'
export const HOWTOUSE = '/aprenda-a-usar-florais-de-bach'
export const SYSTEMICTERAPY = '/terapia-floral-sistemica'
export const CONSTELACAO_FAMILIAR_SISTEMICA = '/constelacao-familiar-sistemica'
export const FLORAIS_BACH_CRIANCAS = '/florais-de-bach-para-criancas'
export const TERAPIA_A_Z = '/terapia-floral-de-A-a-Z'
export const WHATSAPPLINK =
  'https://api.whatsapp.com/send?phone=5551993145751&text=Ol%C3%A1,%20me%20chamo%20Luciana%20e%20estou%20aqui%20para%20tirar%20suas%20d%C3%BAvidas.%20Clique%20em%20enviar%20e%20vamos%20conversar.'
